import React, {useEffect, useState} from "react";
import {Editor} from "react-draft-wysiwyg";
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";


const HTMLEditor = ({value, onChange}) => {

    const [state, setState] = useState(EditorState.createEmpty());
    const [val, setVal] = useState('');

    useEffect(() => {
        if(val !== value) {
            const contentBlock = htmlToDraft(value);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                setState(EditorState.createWithContent(contentState))
            }
        }
    }, [value])


    return <Editor
        editorState={state}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={e => {
            setState(e);
            let v = draftToHtml(convertToRaw(state.getCurrentContent()));
            setVal(v);
            onChange(v);
        }}
    />
}

export default HTMLEditor;
