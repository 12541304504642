import React from "react";
import {Link} from "react-router-dom";

class Heading extends React.Component {

    render() {
        return (
            <div className="heading-container">
            <div className="heading">
                <ul>
                    <li>{this.props.name} <Link to={this.props.link}>{this.props.linkName}</Link></li>
                </ul>

                <Link to="/logout">
                    Logout
                </Link>
            </div>
                <div className="filters">
                {this.props.filters ? this.props.filters : <></>}
                </div>
            </div>

        )
    }
}

export default Heading;
