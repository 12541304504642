import {Link} from "react-router-dom";
import React from "react";
import Api from "../../helpers/Api";

const Nav = ()=>{


    const triggerBuild = ()=>{
        Api.call("/cloudflare/build/trigger","POST").then(()=>{
            alert("New build triggered");
        }).catch(()=>{
            alert("Build trigger failed");
        })
    };
    return <ul>
        <li><Link to="/app/translations">Translations</Link></li>
        <li><Link to="/app/team">Team</Link></li>
        <li><Link to="/app/plans">Plans</Link></li>
        <li><Link to="/app/programs">Programs</Link></li>
        <li><Link to="/app/users">Users</Link></li>
        <li><Link to="/app/images">Images</Link></li>
        <li><Link to="/app/pages">Pages</Link></li>
        <li><Link to="/app/challenges">Challenges</Link></li>
        <li><Link to="/app/posts">Blog Posts</Link></li>
        <li><Link to="/app/faqs">Faqs</Link></li>
        <li><Link to="/app/comments">OP Comments</Link></li>
        <li><Link to="/app/products">Portfolio</Link></li>
        <li><Link onClick={triggerBuild}>Trigger Build</Link></li>
    </ul>
};
export default Nav;
