import React, {Component} from 'react';
import {Switch, BrowserRouter} from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import DefaultRoute from "./Components/Routes/DefaultRoute";
import Team from "./Main/Dashboard/Team/Team";
import UserRoute from "./Components/Routes/UserRoute";
import Login from "./Users/Login";
import Api from "./helpers/Api";
import DataStore from "./helpers/DataStore";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ready: false
        }
    }
    async componentDidMount() {
        await DataStore.initialize();
        this.setState({ready:true});
    }

    render() {

        if (!this.state.ready) {
            return <div/>;
        }
        return (
            <BrowserRouter>
                <Switch>
                    <DefaultRoute path="/app" name="home"/>
                    <UserRoute path="/" name="login"/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
