import React from 'react'
import {Link} from "react-router-dom";
import DataStore from "../../helpers/DataStore";
import Api from "../../helpers/Api";
import Nav from "../Nav/Nav";

class Aside extends React.Component {


    render() {
        return (
            <div className="aside">
                <div className="logo">
                    <Link to="/app/">
                        <img width="50px" src="../../../logo.svg" alt="logo"/>
                    </Link>
                </div>
                <div className="menu">
                    <Nav/>
                </div>
            </div>
        )
    }
}

export default Aside;
