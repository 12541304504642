import React, {Component} from 'react';
import Pagination from "../Paginaton/Pagination";
import {ExportToCsv} from 'export-to-csv';

class Table extends Component {

    renderRow = (row, index) => {
        let columnIndex = 0;
        return <tr>
            {row.map(column => {
                return this.renderColumn(column, columnIndex++);
            })}</tr>
    };

    renderHeader = (columns) => {
        let columnIndex = 0;
        return columns.map(column => {
            return this.renderHeaderColumn(column, columnIndex++);
        })

    };
    export = () => {
        let columns = Object.keys(this.props.rows[0]).map(column => {
            return column;
        });
        let rows = (this.props.rows);
        console.log(rows.length, "Rows to export");

        var today = new Date();
        var date = today.getFullYear() + '_' + (today.getMonth() + 1) + '_' + today.getDate();
        var time = today.getHours() + "_" + today.getMinutes() + "_" + today.getSeconds();
        var dateTime = date + ' ' + time;
        let filename = `export_${dateTime}.csv`;

        rows = rows.map(row => {
            return Object.keys(row).map((key, index) => {
                if (typeof row[key] == 'object' && row[key]) {
                    return row[key].username ? row[key].username : row[key].id;
                } else {
                    return row[key];
                }
            });
        });


        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            filename: filename,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: columns
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(rows);
        return;
        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(",")).join("\n");
        csvContent = csvContent.replace('__actions__', '');
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);

        document.body.appendChild(link); // Required for FF
        link.setAttribute("download",);
        link.click();
    };

    renderColumn = (column, index) => {
        return <td>{column}</td>
    };

    renderHeaderColumn = (column, index) => {
        return <th>{column.name}</th>
    };

    renderLoading = () => {
        return <tr>
            <td colSpan={this.props.columns.length}>Loading...</td>
        </tr>
    };

    render() {
        let rowIndex = 0;

        let props = this.props;
        if (props.renderRow) {
            this.renderRow = props.renderRow;
        }
        if (props.renderColumn) {
            this.renderColumn = props.renderColumn;
        }
        if (props.renderHeaderColumn) {
            this.renderHeaderColumn = props.renderHeaderColumn;
        }
        return (
            <div>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            {this.renderHeader(props.columns)}
                        </tr>
                        </thead>
                        <tbody>
                        {this.props.loading ?
                            this.renderLoading() :
                            props.rows.map(row => {
                                return this.renderRow(row, rowIndex++);
                            })
                        }
                        </tbody>
                    </table>
                </div>
                {/*<Pagination total={this.props.total} currentPage={this.props.currentPage} perPage={this.props.perPage}
                            onChangePerPage={(value) => {
                                if (this.props.onChangePerPage) {
                                    this.props.onChangePerPage(value)
                                }
                            }} onExport={() => {

                    this.export();
                }
                }
                            onPaginate={this.props.onPaginate}/>*/}
            </div>
        );
    }
}

export default Table;


