import React from 'react'
import {Route} from 'react-router-dom';
import Login from "../../Users/Login";
import Splash from "./Splash";
import Logout from "../../Users/Logout";

const UserRoute = ({component: Component}) => {

    return (
        <Route
            render={props => (
                <div className="wrapper">
                    <div className="user">
                        <Route exact path="/" component={Splash}/>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/logout" component={Logout}/>
                    </div>
                </div>
            )}
        />
    )
};

export default UserRoute;
