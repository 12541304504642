import React from 'react'
import {Form, Button} from "react-bootstrap";
import SubmitButton from "../Main/Dashboard/components/SubmitButton";
import Api from "../helpers/Api";
import DataStore from "../helpers/DataStore";
import Error from "../Components/Error/Error";

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            form: {
                identifier: '',
                password: ''
            },
            error:''
        }
    }

    componentDidMount() {

        DataStore.clear();
        setTimeout(()=>{
            this.props.history.push('/login');
        },1000)

    }

    onChange = (key, value) => {
        let {form} = this.state;
        form[key] = value;
        this.setState({form: form});
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({loading:true,error:''});
        Api.call("/auth/local","POST",this.state.form,false,false).then(response=>{
            DataStore.setToken(response.jwt);
            DataStore.setUser(response.user);


            setTimeout(()=>{
                this.setState({loading:false});

                this.props.history.push('/app/posts');
            },1000);
        }).catch(error=>{
            this.setState({error:"Invalid Credentials",loading:false});
        });
    };

    render() {
        return (
            <div className="login">
                <div className="d-flex justify-content-center w-100 mb-5">
                    <h4>Buildup</h4>
                    <br/>
                    <h6>Please wait...</h6>
                </div>


            </div>
        )
    }
}

export default Login
