import React from 'react'
import {Col, Form, Row} from "react-bootstrap";
import Api from "../../../helpers/Api";
import SubmitButton from "../components/SubmitButton";
import Heading from "../../../Components/Heading/Heading";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FileUpload from "../Programs/FileUpload/FileUpload";
import FileUploadMulti from "../Programs/FileUpload/FileUploadMulti";

class ProductForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            post: {screenshots:[]},
            files: [],
            users:[],
        }
    }

    componentDidMount() {
        let {match: {params}} = this.props;
        if (params.id) {
            this.loadData(params.id);
        }
        Api.call(`/users?_sort=firstName:ASC`).then(posts => {
            this.setState({users: posts})
        }).catch(console.log).finally(_ => {

        });
    }

    loadData = (id) => {
        if (id === 'new') {
            return;
        }
        Api.call(`/products/${id}`).then(post => {
            if(!post.screenshots){
                post.screenshots = [];
            }
            this.setState({post});
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        });
    };

    submit = (e) => {
        e.preventDefault();
        if (this.state.post.id) {
            this.update();
        } else {
            this.create();
        }
    };

    update = () => {
        this.setState({loading: true});
        Api.call(`/products/${this.state.post.id}`, "POST", {...this.state.post}).then(post => {
            //this.setState({post: post});
            this.props.history.push('/app/products');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    create = () => {
        this.setState({loading: true});
        let {post, files} = this.state;
        let file = files.thumbnail;


        let formData = new FormData();
        formData.append("data", JSON.stringify(post));
        console.log(JSON.stringify(post));
        console.log(JSON.stringify(post));
        if (file) {
            formData.append("files[thumbnail]", file);
        }
        console.log(formData);

        Api.call(`/products`, "POST", {...post}, false).then(post => {
            //this.setState({post: post});

            this.props.history.push('/app/products');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    onChange = (key, value) => {
        console.log(key, value);
        let {post} = this.state;
        post[key] = value;
        this.setState({post: post});
    };

    onFileChange = (key, value) => {
        console.log(key, value);
        let {files} = this.state;
        files[key] = value;
        var reader = new FileReader();

        this.setState({files: files});
        let url = reader.readAsDataURL(value);

        reader.onloadend = function (e) {
            this.setState({
                previewUrl: [reader.result]
            });
        }.bind(this);
    };


    render() {
        let {post} = this.state;
        return (
            <div className="dashboard">

                <Heading name="Edit"/>

                <div style={{marginTop: '70px'}}>
                    <Row>
                        <Col md={6}>
                            <Form autoComplete={'off'} onSubmit={this.submit}>


                                <Form.Group controlId="key">
                                    <Form.Label>Url</Form.Label>
                                    <Form.Control value={post.key}
                                                  onChange={event => this.onChange('key', event.target.value)}
                                                  placeholder=""/>
                                </Form.Group>
                                <Form.Group controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control value={post.name}
                                                  onChange={event => this.onChange('name', event.target.value)}
                                    />
                                </Form.Group>

                                <FileUpload label="Logo" data={post.logo} onChange={v => this.onChange('logo', v)}/>

                                <Form.Group controlId="slogan">
                                    <Form.Label>Short Slogan</Form.Label>
                                    <Form.Control value={post.slogan}
                                                  onChange={event => this.onChange('slogan', event.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="tags">
                                    <Form.Label>Tags (Comma Separated)</Form.Label>
                                    <Form.Control value={post.tags}
                                                  onChange={event => this.onChange('tags', event.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="batch">
                                    <Form.Label>Batch</Form.Label>
                                    <Form.Control value={post.batch}
                                                  onChange={event => this.onChange('batch', event.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="website">
                                    <Form.Label>Website</Form.Label>
                                    <Form.Control value={post.website}
                                                  onChange={event => this.onChange('website', event.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="linkedin">
                                    <Form.Label>Linkedin</Form.Label>
                                    <Form.Control value={post.linkedin}
                                                  onChange={event => this.onChange('linkedin', event.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="facebook">
                                    <Form.Label>Facebook</Form.Label>
                                    <Form.Control value={post.facebook}
                                                  onChange={event => this.onChange('facebook', event.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="twitter">
                                    <Form.Label>Twitter</Form.Label>
                                    <Form.Control value={post.twitter}
                                                  onChange={event => this.onChange('twitter', event.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="youtube">
                                    <Form.Label>Youtube</Form.Label>
                                    <Form.Control value={post.youtube}
                                                  onChange={event => this.onChange('youtube', event.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="challenge">
                                    <Form.Label>Challenge</Form.Label>
                                    <Form.Control as='textarea' value={post.challenge}
                                                  onChange={event => this.onChange('challenge', event.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="description">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as='textarea' value={post.description}
                                                  onChange={event => this.onChange('description', event.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="features">
                                    <Form.Label>Features</Form.Label>
                                    <Form.Control as='textarea' value={post.features}
                                                  onChange={event => this.onChange('features', event.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="benefits">
                                    <Form.Label>Benefits</Form.Label>
                                    <Form.Control as='textarea' value={post.benefits}
                                                  onChange={event => this.onChange('benefits', event.target.value)}
                                    />
                                </Form.Group>


                                <Form.Group controlId="video">
                                    <Form.Label>Youtube Video Embed</Form.Label>
                                    <Form.Control value={post.video}
                                                  onChange={event => this.onChange('video', event.target.value)}
                                    />
                                </Form.Group>

                                <FileUploadMulti label="Screenshot" data={post.screenshots} onChange={v => this.onChange('screenshots', v)}/>


                                <Form.Group controlId="enabled">
                                    <Form.Check type="checkbox" checked={post.enabled}
                                                onChange={event => this.onChange('enabled', event.target.checked)}
                                                label="Enabled"/>
                                </Form.Group>


                                <SubmitButton label={'Submit'} loading={this.state.loading}/>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default ProductForm
