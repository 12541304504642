import React from 'react'
import {Col, Form, Row} from "react-bootstrap";
import Api from "../../../helpers/Api";
import SubmitButton from "../components/SubmitButton";
import Heading from "../../../Components/Heading/Heading";
import HTMLEditor from "../../../Components/HTMLEditor";

const DATA = {
    title: '',
    category: {label:'',value:''},
    target_model_1: {label:'',value:''},
    target_model_2: {label:'',value:''},
    problem_summary: '',
    problem_nature: '',
    problem_location: '',
    steps: [{step: '', pain: ''}],
    stakeholders: '',
    video_url: '',
    video_embed_url: '',
}
const DATA_ORDER = {
    title: 0,
    category: 1,
    target_model_1: 2,
    target_model_2: 3,
    problem_summary: 4,
    problem_nature: 5,
    problem_location: 6,
    steps: 7,
    stakeholders: 8,
    video_url: 9,
    video_embed_url: 10
}

class ChallengeForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            post: {},
            files: [],
            users : []
        }
    }

    componentDidMount() {
        let {match: {params}} = this.props;
        if (params.id) {
            this.loadData(params.id);
        }
        Api.call(`/users?_sort=firstName:ASC`).then(posts => {
            this.setState({users: posts})
        }).catch(console.log).finally(_ => {

        });
    }

    loadData = (id) => {
        if (id === 'new') {
            return;
        }
        Api.call(`/challenges/${id}`).then(post => {
            if(post.mentor){
                post.mentor = post.mentor.id;
            }
            this.setState({post});
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        });
    };

    submit = (e) => {
        e.preventDefault();
        if (this.state.post.id) {
            this.update();
        } else {
            this.create();
        }
    };

    update = () => {
        this.setState({loading: true});
        Api.call(`/challenges/${this.state.post.id}`, "POST", this.state.post).then(post => {
            if(post.mentor){
                post.mentor = post.mentor.id;
            }
            this.setState({post: post});
            this.props.history.push('/app/challenges');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    create = () => {
        this.setState({loading: true});
        let {post} = this.state;

        Api.call(`/challenges`, "POST", post, false).then(post => {
            if(post.mentor){
                post.mentor = post.mentor.id;
            }
            this.setState({post: post});

            this.props.history.push('/app/challenges');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    onChange = (key, value) => {
        let {post} = this.state;
        post[key] = value;
        this.setState({post: post});
    };

    setToValue = (obj, value, path) => {
        path = path.split('.');
        var i;
        for (i = 0; i < path.length - 1; i++)
            obj = obj[path[i]];

        obj[path[i]] = value;
    }

    onFileChange = (key, value) => {
        console.log(key, value);
        let {files} = this.state;
        files[key] = value;
        var reader = new FileReader();

        this.setState({files: files});
        let url = reader.readAsDataURL(value);

        reader.onloadend = function (e) {
            this.setState({
                previewUrl: [reader.result]
            });
        }.bind(this);
    };

    sortedData = (data) => {
        return Object.keys(data).sort(function (x, y) {
            if (DATA_ORDER[x] < DATA_ORDER[y]) {
                return -1;
            }
            if (DATA_ORDER[x] > DATA_ORDER[y]) {
                return 1;
            }
            return 0;
        }).reduce(
            (obj, key) => {
                obj[key] = data[key];
                return obj;
            }, {});
    };

    render() {
        let {post} = this.state;
        let data = post.data;
        if (!data) {
            data = JSON.parse(JSON.stringify(DATA));
        }
        data = this.sortedData(data);
        return (
            <div className="dashboard">

                <Heading name="Edit"/>

                <div style={{marginTop: '70px'}}>
                    <Row>
                        <Col md={6}>
                            <Form autoComplete={'off'} onSubmit={this.submit}>


                                <Form.Group controlId="enabled">
                                    <Form.Check type="checkbox" checked={post.enabled}
                                                onChange={event => this.onChange('enabled', event.target.checked)}
                                                label="Enabled"/>
                                </Form.Group>

                                <Form.Group controlId={'mentor'}>
                                    <Form.Label>Mentor</Form.Label>
                                    <Form.Control as={'select'} value={post.mentor}
                                                  onChange={event => this.onChange('mentor', event.target.value)}>
                                        <option value={''}></option>
                                        {this.state.users.map(category => {
                                            return <option value={category.id}>{`${category.firstName} ${category.lastName}`.trim()}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>


                                <Component border={false} data={data} label={''} onChange={(d) => {
                                    this.onChange('data', d);
                                }}/>

                                <SubmitButton label={'Submit'} loading={this.state.loading}/>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const Component = ({data, onChange, label, allowDelete, onDelete, border = true, flexDirection = 'column'}) => {
    if (typeof data === 'string' || data === null) {

        let component = <Form.Group controlId="enabled">
            <Form.Group controlId="price">
                <Form.Label>{label.replaceAll('_', ' ')} {allowDelete &&
                <button className={'float-right'} type={'button'} onClick={onDelete}>Delete</button>}</Form.Label>
                <Form.Control
                    type={'text'}
                    value={data}
                    onChange={event => onChange(event.target.value)}
                    placeholder=""/>
            </Form.Group>
        </Form.Group>

        if (label.includes('description')) {
            component = <Form.Group controlId="enabled">
                <Form.Group controlId="price">
                    <Form.Label>{label.replaceAll('_', ' ')} {allowDelete &&
                    <button className={'float-right'} type={'button'} onClick={onDelete}>Delete</button>}</Form.Label>
                    <Form.Control
                        type='text'
                        as='textarea'
                        value={data}
                        onChange={event => onChange(event.target.value)}
                        placeholder=""/>
                </Form.Group>
            </Form.Group>;
        }
        if (label.includes('answer')) {
            component = <Form.Group controlId="enabled">
                <Form.Group controlId="price">
                    <Form.Label>{label.replaceAll('_', ' ')} {allowDelete &&
                    <button className={'float-right'} type={'button'} onClick={onDelete}>Delete</button>}</Form.Label>
                    <HTMLEditor value={data} onChange={onChange}/>
                </Form.Group>
            </Form.Group>;
        }

        return <>
            {component}
        </>;
    }
    if (typeof data === 'boolean') {
        return <Form.Group controlId={label}>
            <Form.Check type="checkbox" checked={data}
                        onChange={event => onChange(event.target.checked)}
                        label={label.replaceAll('_', ' ')}/>
        </Form.Group>
    }
    if (Array.isArray(data)) {
        return <ArrayComponent data={data} label={label} onChange={onChange}/>;
    }
    if (typeof data === 'object') {
        return <><ObjectComponent border={border} data={data} label={label} allowDelete={allowDelete}
                                  onDelete={onDelete}
                                  onChange={onChange}/>
        </>;
    }

}

const ArrayComponent = ({data, onChange, label}) => {


    const skeleton = (source) => {
        console.log(typeof source === 'string');
        console.log({source});
        if (typeof source === 'string') {
            return '';
        }
        var o = Array.isArray(source) ? [] : {};
        for (var key in source) {
            if (source.hasOwnProperty(key)) {
                var t = typeof source[key];
                o[key] = t === 'object' ? skeleton(source[key]) : {string: '', number: 0, boolean: false}[t];
            }
        }
        return o;
    }

    return <div className={'array'}>
        <div><span className={'title'}>{label.replaceAll('_', ' ')}</span>
            <button className={'float-right'} type={'button'} onClick={() => {
                let _data = [...data];
                _data.push(skeleton(_data[0]));
                onChange(_data);
            }}>Add
            </button>
        </div>
        {data.map((d, index) => {
            return <><Component data={d} onChange={(v) => {
                let _data = [...data];
                _data[index] = v;
                onChange(_data);
            }} label={`${index + 1}`} flexDirection={'row'} allowDelete={index > 0} onDelete={() => {
                let _data = [...data];
                _data.splice(index, 1);
                onChange(_data);
            }}/>
            </>

        })}
    </div>
}

const ObjectComponent = ({data, border = true, onChange, label, allowDelete, onDelete}) => {
    return <div className={`object ${border ? '' : 'no-border'}`}>
        <div><span className={`title`}>{label.replaceAll('_', ' ')}
            {allowDelete && <button className={'float-right'} type={'button'} onClick={onDelete}>Delete</button>}
        </span></div>
        {Object.keys(data).map(key => {
            return <Component data={data[key]} onChange={(v) => {
                let d = {...data};
                d[key] = v;
                onChange(d)
            }} label={key}/>
        })}
    </div>
}

export default ChallengeForm
