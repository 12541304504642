import React, {useEffect, useRef, useState} from 'react';
import {Form} from "react-bootstrap";
import Api from "../../../../helpers/Api";

const FileUploadMulti = ({data, label, onChange}) => {

    const fileInputRef = useRef(null);

    const [urls, setUrls] = useState(data);

    const onFileChange = (key, value) => {
        if (!value) {
            return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(value);

        reader.onloadend = () => {
            const image = reader.result;
            if(fileInputRef.current){
                fileInputRef.current.value = '';
            }
            Api.call(`/programs/files/upload`, "POST", JSON.stringify({image}), true).then(response => {
                let u = [...urls];
                u.push(response.url);
                onChange(u)
            })
                .catch(console.log)
                .finally(() => {});
        }
    };

    useEffect(() => {
        setUrls(data)
    }, [data]);


    return <Form.Group controlId="enabled">
        <Form.Group controlId={`file-${label}`}>
            <Form.Label>{label}</Form.Label>
            <Form.Control ref={fileInputRef}
                type='file'
                onChange={event => onFileChange('image', event.target.files[0])}
                placeholder=""/>
        </Form.Group>
        <div style={{ display:'flex'}}>
        {data.map((d, index)=>{
            return <div style={{position:'relative'}}>
                <span style={{position:'absolute',right:0,top:0, cursor:'pointer', padding:5, background:'#fff', color:'black'}} onClick={()=>{
                    let u = [...urls];
                    u.splice(index, 1);
                    onChange(u)
                }}>x</span>
                <img src={Api.getImageUrl(d, false)} width={200} alt='Image'/>
            </div>
        })}
        </div>
    </Form.Group>;

}

export default FileUploadMulti;
