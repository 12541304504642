import React from 'react'
import {Col, Form, Row} from "react-bootstrap";
import Api from "../../../helpers/Api";
import SubmitButton from "../components/SubmitButton";
import Heading from "../../../Components/Heading/Heading";

class PostForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            post: {},
            files: [],
            countries: [],
            industries: [],
            challenges: []
        }
    }

    componentDidMount() {
        Api.call("/translations/translations").then(response => {
            this.setState({
                countries: response.country_options.split(","),
                industries: response.industry_options.split(",")
            })
        }).catch(console.log)

        let {match: {params}} = this.props;
        if (params.id) {
            this.loadData(params.id);
        }


        Api.call(`/challenges`).then(challenges => {
            this.setState({challenges: challenges})
        }).catch(console.log).finally(_ => {

        });


    }

    loadData = (id) => {
        if (id === 'new') {
            return;
        }
        Api.call(`/users/${id}`).then(post => {
            if(post.challenge){
                post.challenge = post.challenge.id;
            }
            this.setState({post});
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        });
    };

    validate = () => {
        let {post} = this.state;
        if (!post.email) {
            alert("Email is required");
            return false;
        }
        return true;
    };

    submit = (e) => {
        e.preventDefault();
        if (!this.validate()) {
            return;
        }
        if (this.state.post.id) {
            this.update();
        } else {
            this.create();
        }
    };

    update = () => {
        let {post} = this.state;

        if (this.state.image) {
            post.imageData = this.state.image;
        }
        if (this.state.password) {
            post.password = this.state.password;
        }
        this.setState({loading: true});
        Api.call(`/users/${this.state.post.id}`, "POST", post).then(post => {
            if(post.challenge){
                post.challenge = post.challenge.id;
            }
            this.setState({post: post});
            this.props.history.push('/app/users');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    create = () => {
        this.setState({loading: true});

        let {post} = this.state;

        if (this.state.image) {
            post.imageData = this.state.image;
        }

        if (this.state.password) {
            post.password = this.state.password;
        }


        Api.call(`/users`, "POST", post, false).then(post => {
            if(post.challenge){
                post.challenge = post.challenge.id;
            }
            this.setState({post: post});
            this.props.history.push('/app/users');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    onChange = (key, value) => {
        console.log(key, value);
        let {post} = this.state;
        post[key] = value;
        this.setState({post: post});
    };

    getImageUrl = (post) => {
        return Api.getImageUrl(post.image, false);
    };

    onFileChange = (key, value) => {
        if (!value) {
            this.setState({
                image: null
            });
            return;
        }
        console.log(key, value);
        let {files} = this.state;
        files[key] = value;
        var reader = new FileReader();

        this.setState({files: files});
        reader.readAsDataURL(value);

        reader.onloadend = function (e) {
            this.setState({
                image: [reader.result]
            });
        }.bind(this);
    };

    render() {
        let {post} = this.state;
        let imageUrl = this.state.image ? this.state.image : this.getImageUrl(post);
        return (
            <div className="dashboard">
                <Heading name="Edit"/>
                <div style={{marginTop: '70px'}}>
                    <Row>
                        <Col md={6}>
                            <Form autoComplete={'off'} onSubmit={this.submit}>
                                <Form.Group controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" value={post.email}
                                                  onChange={event => this.onChange('email', event.target.value)}
                                                  rows="2"/>
                                </Form.Group>
                                <Form.Group controlId="firstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" value={post.firstName}
                                                  onChange={event => this.onChange('firstName', event.target.value)}
                                                  rows="2"/>
                                </Form.Group>
                                <Form.Group controlId="lastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" value={post.lastName}
                                                  onChange={event => this.onChange('lastName', event.target.value)}
                                                  rows="2"/>
                                </Form.Group>
                                <Form.Group controlId="linkedin">
                                    <Form.Label>Linkedin</Form.Label>
                                    <Form.Control type="text" value={post.linkedin}
                                                  onChange={event => this.onChange('linkedin', event.target.value)}
                                                  rows="2"/>
                                </Form.Group>
                                <Form.Group controlId="calendly">
                                    <Form.Label>Calendly</Form.Label>
                                    <Form.Control type="text" value={post.calendly}
                                                  onChange={event => this.onChange('calendly', event.target.value)}
                                                  rows="2"/>
                                </Form.Group>
                                <Form.Group controlId="twitter">
                                    <Form.Label>Twitter</Form.Label>
                                    <Form.Control type="text" value={post.twitter}
                                                  onChange={event => this.onChange('twitter', event.target.value)}
                                                  rows="2"/>
                                </Form.Group>
                                <Form.Group controlId="position">
                                    <Form.Label>Position</Form.Label>
                                    <Form.Control type="text" value={post.position}
                                                  onChange={event => this.onChange('position', event.target.value)}
                                                  rows="2"/>
                                </Form.Group>


                                <Form.Group controlId="company">
                                    <Form.Label>Company</Form.Label>
                                    <Form.Control type="text" value={post.company}
                                                  onChange={event => this.onChange('company', event.target.value)}
                                                  rows="2"/>
                                </Form.Group>
                                <Form.Group controlId="industry">
                                    <Form.Label>Industry</Form.Label>
                                    <Form.Control as="select" value={post.industry}
                                                  onChange={event => this.onChange('industry', event.target.value)}>
                                        {this.state.industries.map((c, i) => {
                                            return <option key={i}>{c}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="country">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control as="select" value={post.country}
                                                  onChange={event => this.onChange('country', event.target.value)}>
                                        {this.state.countries.map((c, i) => {
                                            return <option key={i}>{c}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="mentorship_interests">
                                    <Form.Label>Mentorship Interests</Form.Label>
                                    <Form.Control type="text" value={post.interests?.join(",")}
                                                  onChange={event => this.onChange('interests', event.target.value?.split(","))}
                                                  rows="2"/>
                                </Form.Group>
                                <Form.Group controlId="role">
                                    <Form.Label>Role</Form.Label>
                                    <Form.Control as="select" value={post.role}
                                                  onChange={event => this.onChange('role', event.target.value)}>
                                        <option value={'ROLE_ADMIN'}>ROLE_ADMIN</option>
                                        <option value={'ROLE_DEAN'}>ROLE_DEAN</option>
                                        <option value={'ROLE_STUDENT'}>ROLE_STUDENT</option>
                                        <option value={'ROLE_USER'}>ROLE_USER</option>
                                        <option value={'ROLE_TRUSTEE_LIMITED'}>ROLE_TRUSTEE_LIMITED</option>
                                        <option value={'ROLE_TRUSTEE_FULL'}>ROLE_TRUSTEE_FULL</option>
                                    </Form.Control>
                                </Form.Group>
                                {post.role === 'ROLE_STUDENT' &&
                                <Form.Group controlId="challenge">
                                    <Form.Label>Challenge</Form.Label>
                                    <Form.Control as="select" value={post.challenge}
                                                  onChange={event => this.onChange('challenge', event.target.value)}>
                                        <option value={''}>Select Challenge</option>
                                        {this.state.challenges.map(c=>{
                                            return <option value={c.id}>{c.data.title}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                                }
                                <Form.Group controlId="thumbnail">
                                    <Form.Label>Image</Form.Label>
                                    <Form.Control type="file"
                                                  onChange={event => this.onFileChange('image', event.target.files[0])}/>
                                </Form.Group>


                                {imageUrl &&
                                <><img src={imageUrl} width={200} alt='Image'/><br/></>
                                }
                                <Form.Group controlId="enabled">
                                    <Form.Check type="checkbox" checked={post.enabled}
                                                onChange={event => this.onChange('enabled', event.target.checked)}
                                                label="Enabled"/>
                                </Form.Group>
                                <Form.Group controlId="loginEnabled">
                                    <Form.Check type="checkbox" checked={post.loginEnabled}
                                                onChange={event => this.onChange('loginEnabled', event.target.checked)}
                                                label="Login Enabled"/>
                                </Form.Group>
                                <Form.Group controlId="subscribed">
                                    <Form.Check type="checkbox" checked={post.subscribed}
                                                onChange={event => this.onChange('subscribed', event.target.checked)}
                                                label="Subscribed"/>
                                </Form.Group>

                                <Form.Group controlId="password">
                                    <Form.Label>Password (Blank to keep unchanged)</Form.Label>
                                    <Form.Control type="password" value={this.state.password}
                                                  onChange={event => this.setState({password: event.target.value})}
                                                  rows="2"/>
                                </Form.Group>
                                <SubmitButton label={'Submit'} loading={this.state.loading}/>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default PostForm
