import React from 'react'
import {Form, Button} from "react-bootstrap";
import SubmitButton from "../Main/Dashboard/components/SubmitButton";
import Api from "../helpers/Api";
import DataStore from "../helpers/DataStore";
import Error from "../Components/Error/Error";

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            form: {
                identifier: '',
                password: ''
            },
            error:''
        }
    }

    componentDidMount() {
        if(DataStore.getUser()){
            this.props.history.push('/app/team');
        }
    }

    onChange = (key, value) => {
        let {form} = this.state;
        form[key] = value;
        this.setState({form: form});
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({loading:true,error:''});
        Api.call("/users/login","POST",this.state.form,false,false, false).then(response=>{
            DataStore.setToken(response.apiTokens.pop());
            DataStore.setUser(response.user);


            setTimeout(()=>{
                this.setState({loading:false});

                this.props.history.push('/app/team');
            },1000);
        }).catch(error=>{
            this.setState({error:"Invalid Credentials",loading:false});
        });
    };

    render() {
        return (
            <div className="login">
                <div className="d-flex justify-content-center w-100 mb-5">
                    <h4>Buildup</h4>
                </div>

                <Form autoComplete={'off'} onSubmit={this.onSubmit}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text" placeholder="Enter Username" value={this.state.form.email}
                                      onChange={event => this.onChange('email', event.target.value)}/>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" value={this.state.form.password}
                                      onChange={event => this.onChange('password', event.target.value)}/>
                    </Form.Group>
                    <Error error={this.state.error}/>
                    <SubmitButton label={'Login'} loading={this.state.loading}/>
                </Form>
            </div>
        )
    }
}

export default Login
