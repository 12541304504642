import React, {Component} from 'react';

class Splash extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: true,
            overlay: true,
        }
    }

    componentDidMount() {
        const {history} = this.props;
        setTimeout(() => {
            history.push('login')
        }, 0);

        setInterval(() => {
            this.setState({overlay: false})
        }, 2000);
    }

    render() {
        return (
            <div className="float-left w-100">
            </div>
        );
    }
}

export default Splash;
