import React from 'react'
import {Route} from 'react-router-dom';
import Aside from "../Aside/Aside";
import Header from "../Header/Header";
import Team from "../../Main/Dashboard/Team/Team";
import Plans from "../../Main/Dashboard/Plans/Plans";
import Notifications from "../../Main/Dashboard/Notifications/Notifications";
import Categories from "../../Main/Dashboard/Categories/Categories";
import TeamForm from "../../Main/Dashboard/Team/TeamForm";
import PlanForm from "../../Main/Dashboard/Plans/PlanForm";
import Translations from "../../Main/Dashboard/Translations/Translations";
import Users from "../../Main/Dashboard/Users/Users";
import CategoryForm from "../../Main/Dashboard/Categories/CategoryForm";
import NotificationForm from "../../Main/Dashboard/Notifications/NotificationForm";
import TranslationForm from "../../Main/Dashboard/Translations/TranslationForm";
import UserForm from "../../Main/Dashboard/Users/UserForm";
import Images from "../../Main/Dashboard/HomepageImage/Images";
import ImageForm from "../../Main/Dashboard/HomepageImage/ImageForm";
import Programs from '../../Main/Dashboard/Programs/Programs'
import ProgramForm from '../../Main/Dashboard/Programs/ProgramForm'
import Pages from "../../Main/Dashboard/Pages/Pages";
import PageForm from "../../Main/Dashboard/Pages/PageForm";
import Challenges from "../../Main/Dashboard/Challenges/Challenges";
import ChallengeForm from "../../Main/Dashboard/Challenges/ChallengeForm";
import Posts from "../../Main/Dashboard/Posts/Posts";
import PostForm from "../../Main/Dashboard/Posts/PostForm";
import Faqs from "../../Main/Dashboard/Faqs/Faqs";
import FaqForm from "../../Main/Dashboard/Faqs/FaqForm";
import CommentForm from "../../Main/Dashboard/Comments/CommentForm";
import Comments from "../../Main/Dashboard/Comments/Comments";
import Products from "../../Main/Dashboard/Products/Products";
import ProductForm from "../../Main/Dashboard/Products/ProductForm";

const DefaultRoute = ({component: Component}) => {

    return (
        <Route
            render={props => (
                <div className="wrapper">
                    {/*Aside bar for main section*/}
                    <Aside/>
                    <Header/>
                    {/*Side bar for main section*/}
                    <div className="right_container">
                        <Route exact path="/app/team" component={Team}/>
                        <Route exact path="/app/team/:id" component={TeamForm}/>
                        <Route exact path="/app/images" component={Images}/>
                        <Route exact path="/app/images/:id" component={ImageForm}/>
                        <Route exact path="/app/plans" component={Plans}/>
                        <Route exact path="/app/plans/:id" component={PlanForm}/>
                        <Route exact path="/app/pages" component={Pages}/>
                        <Route exact path="/app/pages/:id" component={PageForm}/>
                        <Route exact path="/app/posts" component={Posts}/>
                        <Route exact path="/app/posts/:id" component={PostForm}/>
                        <Route exact path="/app/programs" component={Programs}/>
                        <Route exact path="/app/programs/:id" component={ProgramForm}/>
                        <Route exact path="/app/products" component={Products}/>
                        <Route exact path="/app/products/:id" component={ProductForm}/>
                        <Route exact path="/app/notifications" component={Notifications}/>
                        <Route exact path="/app/notifications/:id" component={NotificationForm}/>
                        <Route exact path="/app/categories" component={Categories}/>
                        <Route exact path="/app/categories/:id" component={CategoryForm}/>
                        <Route exact path="/app/translations" component={Translations}/>
                        <Route exact path="/app/translations/:id" component={TranslationForm}/>
                        <Route exact path="/app/users" component={Users}/>
                        <Route exact path="/app/users/:id" component={UserForm}/>
                        <Route exact path="/app/challenges" component={Challenges}/>
                        <Route exact path="/app/challenges/:id" component={ChallengeForm}/>
                        <Route exact path="/app/faqs" component={Faqs}/>
                        <Route exact path="/app/faqs/:id" component={FaqForm}/>
                        <Route exact path="/app/comments" component={Comments}/>
                        <Route exact path="/app/comments/:id" component={CommentForm}/>
                    </div>
                </div>
            )}
        />
    )
};

export default DefaultRoute;
