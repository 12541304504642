import React, {Component} from 'react';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import {Link} from "react-router-dom";
import Nav from "../Nav/Nav";

class SlideView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPaneOpen: false,
            isPaneOpenLeft: false
        };
    }

    componentDidMount() {
        Modal.setAppElement(this.el);
    }

    hideSlidingPane = ()=>{
        this.setState({isPaneOpenLeft:false});
    };

    render() {
        return (
            <div ref={ref => this.el = ref}>
                <button className="profile bg-transparent border-0"
                        onClick={() => this.setState({isPaneOpenLeft: true})}>
                    <img width="20px" src="../../../menu.png" alt="profile"/>
                </button>
                <SlidingPane
                    isOpen={this.state.isPaneOpenLeft}
                    title='back'
                    from='right'
                    width='600px'
                    onRequestClose={() => this.setState({isPaneOpenLeft: false})}>
                    <section className="menu">
                        <Nav/>
                    </section>
                </SlidingPane>
            </div>
        )
    }
}

export default SlideView;
