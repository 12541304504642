import React from 'react'
import {Form, Button, Row, Col} from "react-bootstrap";
import Api from "../../../helpers/Api";
import SubmitButton from "../components/SubmitButton";
import Heading from "../../../Components/Heading/Heading";

class PostForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            post: {},
            files: [],
            previewUrl: null
        }
    }

    componentDidMount() {
        let {match: {params}} = this.props;
        if (params.id) {
            this.loadData(params.id);
        }
    }

    loadData = (id) => {
        if (id === 'new') {
            return;
        }
        Api.call(`/categories/${id}`).then(post => {
            this.setState({post});
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        });
    };

    validate = ()=>{
        let {post} = this.state;
        if(!post.name){
            alert("Name is required");
            return false;
        }
        return true;
    };

    submit = (e) => {
        e.preventDefault();
        if(!this.validate()){
            return;
        }
        e.preventDefault();
        if (this.state.post.id) {
            this.update();
        } else {
            this.create();
        }
    };

    update = () => {
        this.setState({loading: true});
        let {files,post} = this.state;

        let file = files.icon;

        let formData = new FormData();
        formData.append("data", JSON.stringify(post));
        console.log(JSON.stringify(post));
        if (file) {
            formData.append("files[icon]", file);
        }


        Api.call(`/categories/${this.state.post.id}`, "POST", formData,true).then(post => {
            this.setState({post: post});
            this.props.history.push('/app/categories');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    create = () => {
        this.setState({loading: true});
        let {post, files} = this.state;
        let file = files.icon;


        let formData = new FormData();
        formData.append("data", JSON.stringify(post));
        if (file) {
            formData.append("files[icon]", file);
        }
        console.log(formData);
        Api.call(`/categories`, "POST", formData, true).then(post => {
            this.setState({post: post});
            this.props.history.push('/app/categories');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    onChange = (key, value) => {
        console.log(key, value);
        let {post} = this.state;
        post[key] = value;
        this.setState({post: post});
    };

    onFileChange = (key, value) => {
        if (!value) {
            this.setState({
                previewUrl: null
            });
            return;
        }
        console.log(key, value);
        let {files} = this.state;
        files[key] = value;
        var reader = new FileReader();

        this.setState({files: files});
        reader.readAsDataURL(value);

        reader.onloadend = function (e) {
            this.setState({
                previewUrl: [reader.result]
            });
        }.bind(this);
    };

    getImageUrl = (post) => {
        if (this.state.previewUrl) {
            return this.state.previewUrl;
        }

        return Api.getImageUrl(post.icon, false);
    };

    render() {
        let {post} = this.state;

        let imageUrl = this.getImageUrl(post);
        return (
            <div className="dashboard">

                <Heading name="Edit"/>

                <div style={{marginTop:'70px'}}>
                    <Row>
                        <Col md={6}>
                            <Form autoComplete={'off'} onSubmit={this.submit}>
                                <Form.Group controlId="title">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control type="text" value={post.name}
                                                  onChange={event => this.onChange('name', event.target.value)}
                                                  placeholder="" style={{direction: 'rtl'}}/>
                                </Form.Group>
                                <Form.Group controlId="excerpt">
                                    <Form.Label>Position</Form.Label>
                                    <Form.Control as="textarea" value={post.position}
                                                  onChange={event => this.onChange('position', event.target.value)}
                                                  rows="1"/>
                                </Form.Group>
                                <Form.Group controlId="color">
                                    <Form.Label>Color</Form.Label>
                                    <Form.Control type="text" value={post.color}
                                                  onChange={event => this.onChange('color', event.target.value)}
                                                  placeholder=""/>
                                </Form.Group>
                                <Form.Group controlId="menuColor">
                                    <Form.Label>Menu Color</Form.Label>
                                    <Form.Control type="text" value={post.menuColor}
                                                  onChange={event => this.onChange('menuColor', event.target.value)}
                                                  placeholder=""/>
                                </Form.Group>
                                <Form.Group controlId="postTitleColor">
                                    <Form.Label>Post Title Color</Form.Label>
                                    <Form.Control type="text" value={post.postTitleColor}
                                                  onChange={event => this.onChange('postTitleColor', event.target.value)}
                                                  placeholder=""/>
                                </Form.Group>
                                <Form.Group controlId="postContentColor">
                                    <Form.Label>Post Content Color</Form.Label>
                                    <Form.Control type="text" value={post.postContentColor}
                                                  onChange={event => this.onChange('postContentColor', event.target.value)}
                                                  placeholder=""/>
                                </Form.Group>

                                <Form.Group controlId="notify">
                                    <Form.Check type="checkbox" checked={post.enabled}
                                                onChange={event => this.onChange('enabled', event.target.checked)}
                                                label="Enabled"/>
                                </Form.Group>
                                <Form.Group controlId="icon">
                                    <Form.Label>Icon</Form.Label>
                                    <Form.Control type="file"
                                                  onChange={event => this.onFileChange('icon', event.target.files[0])}/>
                                </Form.Group>
                                {imageUrl &&
                                <img src={imageUrl} width={200} alt='Post'/>
                                }
                                <br/>

                                <SubmitButton label={'Submit'} loading={this.state.loading}/>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default PostForm
