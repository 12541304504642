import React, {Component} from 'react';

class Error extends Component {

    render() {
        return (
            <div color={'red'}>
                {this.props.error}
            </div>
        )
    }
}

export default Error;
