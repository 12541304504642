import React from 'react'
import {Col, Form, Row} from "react-bootstrap";
import Api from "../../../helpers/Api";
import SubmitButton from "../components/SubmitButton";
import DataStore from "../../../helpers/DataStore";
import Heading from "../../../Components/Heading/Heading";

class TeamForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            post: {},
            files: [],
            categories: [{value:'deanAndFaculty',label:'Dean and Faculty'},{value:'managingTeam',label:'Managing Team'},{value:'boardMembers',label:'Board Member'},{value:'honoredMembers',label:'Honored Member'},{value:'eir',label:'EIR'}],
        }
    }

    componentDidMount() {

        let {match: {params}} = this.props;
        if (params.id) {
            this.loadData(params.id);
        }
    }

    loadData = (id) => {
        if (id === 'new') {
            return;
        }
        Api.call(`/team/${id}`).then(post => {
            this.setState({post});
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        });
    };

    validate = () => {
        let {post} = this.state;
        return true;
    };

    submit = (e) => {
        e.preventDefault();
        if (!this.validate()) {
            return;
        }
        if (this.state.post.id) {
            this.update();
        } else {
            this.create();
        }
    };

    randomInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    update = () => {
        this.setState({loading: true});
        let {post, files} = this.state;

        if (post.category) {
            post.category = post.category.id;
        }
        if(this.state.image){
            post.image = this.state.image;
        }

//        post.author = DataStore.getUser().id;
        //let file = files.thumbnail;



        Api.call(`/team/${this.state.post.id}`, "POST", JSON.stringify(post), true).then(post => {
            this.setState({post: post});
            this.props.history.push('/app/team');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    create = () => {
        this.setState({loading: true});
        let {post, files} = this.state;

        if (post.category) {
            post.category = post.category.id;
        }

        if(this.state.image){
            post.image = this.state.image;
        }

        Api.call(`/team`, "POST", JSON.stringify(post), true).then(post => {
            this.setState({post: post});
            this.props.history.push('/app/team');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    onChange = (key, value) => {
        console.log(key, value);
        let {post} = this.state;
        post[key] = value;
        let length = 50;
        this.setState({post: post});
    };

    onFileChange = (key, value) => {
        if (!value) {
            this.setState({
                image: null
            });
            return;
        }
        console.log(key, value);
        let {files} = this.state;
        files[key] = value;
        var reader = new FileReader();

        this.setState({files: files});
        reader.readAsDataURL(value);

        reader.onloadend = function (e) {
            this.setState({
                image: [reader.result]
            });
        }.bind(this);
    };

    getImageUrl = (post) => {
        return Api.getImageUrl(post.imageUrl, false);
    };

    render() {
        let {post} = this.state;
        let imageUrl = this.getImageUrl(post);
        return (
            <div className="dashboard">

                <Heading name="Edit"/>

                <div style={{marginTop: '70px'}}>
                    <Row>
                        <Col md={6}>
                            <Form autoComplete={'off'} onSubmit={this.submit}>
                                <Form.Group controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" value={post.name}
                                                  onChange={event => this.onChange('name', event.target.value)}
                                                  placeholder=""/>
                                </Form.Group>

                                <Form.Group controlId="position">
                                    <Form.Label>Role</Form.Label>
                                    <Form.Control type="text" value={post.position}
                                                  onChange={event => this.onChange('position', event.target.value)}
                                                  rows="4"/>
                                </Form.Group>
                                <Form.Group controlId="bigRole">
                                    <Form.Label>Big Role</Form.Label>
                                    <Form.Control type="text" value={post.bigRole}
                                                  onChange={event => this.onChange('bigRole', event.target.value)}
                                                  rows="4"/>

                                </Form.Group>
                                <Form.Group controlId="content">
                                    <Form.Label>Content</Form.Label>
                                    <Form.Control as="textarea" value={post.content}
                                                  onChange={event => this.onChange('content', event.target.value)}
                                                  rows="2"/>
                                </Form.Group>
                                <Form.Group controlId="facebook">
                                    <Form.Label>Facebook</Form.Label>
                                    <Form.Control type="text" value={post.facebook}
                                                  onChange={event => this.onChange('facebook', event.target.value)}
                                                  rows="4"/>
                                </Form.Group>
                                <Form.Group controlId="twitter">
                                    <Form.Label>Twitter</Form.Label>
                                    <Form.Control type="text" value={post.twitter}
                                                  onChange={event => this.onChange('twitter', event.target.value)}
                                                  rows="4"/>
                                </Form.Group>
                                <Form.Group controlId="linkedin">
                                    <Form.Label>Linkedin</Form.Label>
                                    <Form.Control type="text" value={post.linkedin}
                                                  onChange={event => this.onChange('linkedin', event.target.value)}
                                                  rows="4"/>
                                </Form.Group>
                                <Form.Group controlId="instagram">
                                    <Form.Label>Instagram</Form.Label>
                                    <Form.Control type="text" value={post.instagram}
                                                  onChange={event => this.onChange('instagram', event.target.value)}
                                                  rows="4"/>
                                </Form.Group>
                                <Form.Group controlId="youtube">
                                    <Form.Label>Youtube</Form.Label>
                                    <Form.Control type="text" value={post.youtube}
                                                  onChange={event => this.onChange('youtube', event.target.value)}
                                                  rows="4"/>
                                </Form.Group>

                                    <Form.Group controlId={'type'}>
                                        <Form.Label>Type</Form.Label>
                                        <Form.Control as={'select'} value={post.type}
                                                      onChange={event => this.onChange('type', event.target.value)}>
                                            <option value={''}></option>
                                            {this.state.categories.map(category => {
                                                return <option value={category.value}>{category.label}</option>
                                            })}
                                        </Form.Control>
                                    </Form.Group>

                                <Form.Group controlId="thumbnail">
                                    <Form.Label>Thumbnail</Form.Label>
                                    <Form.Control type="file"
                                                  onChange={event => this.onFileChange('image', event.target.files[0])}/>
                                </Form.Group>


                                {imageUrl &&
                                <><img src={imageUrl} width={200} alt='Image'/><br/></>
                                }


                                <Form.Group controlId="enabled">
                                    <Form.Check type="checkbox" checked={post.enabled}
                                                onChange={event => this.onChange('enabled', event.target.checked)}
                                                label="Enabled"/>
                                </Form.Group>
                                <SubmitButton label={'Submit'} loading={this.state.loading}/>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default TeamForm
