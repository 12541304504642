import React from 'react'
import Table from "../../../Components/Table/Table";
import {Link} from "react-router-dom";
import Api from "../../../helpers/Api";
import Heading from "../../../Components/Heading/Heading";

class Posts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            columns: [
                {name: "#"},
                {name: "key"},
                {name: "Value"},
                {name: "Actions",},
            ],
            posts:[],
            page: 1,
            limit:20,
            total:0,
            show: false,
        }
    }





    loadData = () => {
        let start = 0;
        let {page,limit} = this.state;
        start = (page-1)*limit;
        Api.call("/translations/count").then(total => {
            this.setState({total: total});
        });

        Api.call(`/translations?_sort=id:DESC&_limit=${limit}&_start=${start}`).then(posts=>{
            this.setState({posts:posts})
        }).catch(console.log).finally(_=>{
            this.setState({loading:false});
        });
    };

    onPaginate = (page)=>{
        this.setState({page: page,loading:true},this.loadData)
    };

    componentDidMount() {
        this.loadData();
    }

    parsePatient = (post) => {
        return [post.id,post.key,post.value,'actions'];
    };

    renderRow = (row, index) => {
        let columnIndex = 0;
        let patient = this.parsePatient(row);
        return <tr key={index}>
            {patient.map(column => {
                return this.renderColumn(column, columnIndex++, index, patient);
            })}</tr>
    };

    selectAll = () => {
        let allSelected = !this.state.allSelected;
        let row = this.state.row.map(patient => {
            patient.selected = allSelected;
            return patient;
        });
        this.setState({row: row, allSelected: allSelected});
    };

    onDelete = (event,index)=>{
        event.preventDefault();
      if(!window.confirm('Are you sure you want to delete this item?')){
          return;
      }
      let post = this.state.posts[index];
      Api.call(`/translations/${post.id}`,"DELETE").then(response=>{
          this.loadData();
      })
    };

    renderColumn = (column, index, rowIndex, row) => {
        if (index === row.length - 1) {
            return (
                <td key={index}>
                    <ul>
                        <li>
                            <Link to={`/app/translations/${row[0]}`}>
                                <img width="16px" src="../../../edit.png" alt="edit"/>
                            </Link>
                        </li>
                        <li>
                            <Link to="/" onClick={e=>this.onDelete(e,rowIndex)}>
                                <img width="16px" src="../../../delete.png" alt="edit"/>
                            </Link>
                        </li>
                    </ul>
                </td>
            )
        }
        return <td key={index}>{column}</td>
    };
    renderHeaderColumn = (column, index) => {

        return (
            <th key={index}>
                {column.name}
            </th>
        );
    };

    renderLoading = ()=>{
        return <div className="dashboard">
            <Heading name="Translation"/>
            <div>
                Loading...
            </div>
        </div>
    };
    render() {

        return (
            <div className="dashboard">

                <Heading name="Translation" link={'/app/translations/new'} linkName="Add New"/>

                <div>
                    <Table renderColumn={this.renderColumn} loading={this.state.loading}
                           renderRow={this.renderRow} renderHeaderColumn={this.renderHeaderColumn}
                           columns={this.state.columns} cardFooter="cardFooter" total={this.state.total} currentPage={this.state.page} perPage={this.state.limit}
                           rows={this.state.posts} selected="Invite Selected" onPaginate={this.onPaginate} onChangePerPage={(limit) => {
                        this.setState({limit: limit},this.loadData);
                    }}
                    />
                </div>
            </div>
        )
    }
}

export default Posts
