import React from 'react'
import {Link} from "react-router-dom";
import SlideView from "../SlideView/SlideView";

class Header extends React.Component {

    render() {
        return (
            <div className="header">
                <div className="logo">
                    <Link to="/app">
                        <img width="50px" src="../../../logo.svg" alt="logo"/>
                    </Link>



                </div>
                <div>
                    <SlideView/>
                </div>
            </div>
        )
    }
}

export default Header;
