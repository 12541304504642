import React from 'react'
import {Col, Form, Row} from "react-bootstrap";
import Api from "../../../helpers/Api";
import SubmitButton from "../components/SubmitButton";
import Heading from "../../../Components/Heading/Heading";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import HTMLEditor from "../../../Components/HTMLEditor";
import FileUpload from "../Programs/FileUpload/FileUpload";

class FaqForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            faq: {answer: EditorState.createEmpty(), excerpt:''},
            files: [],
            users:[],
        }
    }

    componentDidMount() {
        let {match: {params}} = this.props;
        if (params.id) {
            this.loadData(params.id);
        }
        Api.call(`/users?_sort=firstName:ASC`).then(faqs => {
            this.setState({users: faqs})
        }).catch(console.log).finally(_ => {

        });
    }

    loadData = (id) => {
        if (id === 'new') {
            return;
        }
        Api.call(`/faqs/${id}`).then(faq => {
            const contentBlock = htmlToDraft(faq.answer);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                faq.answer = EditorState.createWithContent(contentState);
            }
            this.setState({faq});
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        });
    };

    submit = (e) => {
        e.preventDefault();
        if (this.state.faq.id) {
            this.update();
        } else {
            this.create();
        }
    };

    update = () => {
        this.setState({loading: true});
        Api.call(`/faqs/${this.state.faq.id}`, "POST", {...this.state.faq, answer: draftToHtml(convertToRaw(this.state.faq.answer.getCurrentContent()))}).then(faq => {
            //this.setState({faq: faq});
            this.props.history.push('/app/faqs');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    create = () => {
        this.setState({loading: true});
        let {faq, files} = this.state;
        let file = files.thumbnail;


        let formData = new FormData();
        formData.append("data", JSON.stringify(faq));
        console.log(JSON.stringify(faq));
        console.log(JSON.stringify(faq));
        if (file) {
            formData.append("files[thumbnail]", file);
        }
        console.log(formData);

        Api.call(`/faqs`, "POST", {...faq, answer: draftToHtml(convertToRaw(this.state.faq.answer.getCurrentContent()))}, false).then(faq => {
            //this.setState({faq: faq});

            this.props.history.push('/app/faqs');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    onChange = (key, value) => {
        console.log(key, value);
        let {faq} = this.state;
        faq[key] = value;
        this.setState({faq: faq});
    };

    onFileChange = (key, value) => {
        console.log(key, value);
        let {files} = this.state;
        files[key] = value;
        var reader = new FileReader();

        this.setState({files: files});
        let url = reader.readAsDataURL(value);

        reader.onloadend = function (e) {
            this.setState({
                previewUrl: [reader.result]
            });
        }.bind(this);
    };


    render() {
        let {faq} = this.state;
        return (
            <div className="dashboard">

                <Heading name="Edit"/>

                <div style={{marginTop: '70px'}}>
                    <Row>
                        <Col md={6}>
                            <Form autoComplete={'off'} onSubmit={this.submit}>

                                <Form.Group controlId="key">
                                    <Form.Label>Question</Form.Label>
                                    <Form.Control value={faq.question}
                                                  onChange={event => this.onChange('question', event.target.value)}
                                                  placeholder=""/>
                                </Form.Group>

                                <Form.Group controlId="description1">
                                    <Form.Label>Answer</Form.Label>
                                    <Editor
                                        editorState={this.state.faq.answer}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={s=>this.setState({faq: {...faq, answer: s}})}
                                    />
                                </Form.Group>

                                <Form.Group controlId="enabled">
                                    <Form.Check type="checkbox" checked={faq.enabled}
                                                onChange={event => this.onChange('enabled', event.target.checked)}
                                                label="Enabled"/>
                                </Form.Group>


                                <SubmitButton label={'Submit'} loading={this.state.loading}/>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default FaqForm
