import React from 'react'
import {Col, Form, Row} from "react-bootstrap";
import Api from "../../../helpers/Api";
import SubmitButton from "../components/SubmitButton";
import Heading from "../../../Components/Heading/Heading";
import HTMLEditor from "../../../Components/HTMLEditor";


class CommentForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            post: {},
            files: [],
            users : []
        }
    }

    componentDidMount() {
        let {match: {params}} = this.props;
        if (params.id) {
            this.loadData(params.id);
        }
        Api.call(`/users?_sort=firstName:ASC&role=ROLE_STUDENT`).then(posts => {
            this.setState({users: posts})
        }).catch(console.log).finally(_ => {

        });
    }

    loadData = (id) => {
        if (id === 'new') {
            return;
        }
        Api.call(`/comments/${id}`).then(post => {
            if(post.student){
                post.student = post.student.id;
            }
            this.setState({post});
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        });
    };

    submit = (e) => {
        e.preventDefault();
        if (this.state.post.id) {
            this.update();
        } else {
            this.create();
        }
    };

    update = () => {
        this.setState({loading: true});
        Api.call(`/comments/${this.state.post.id}`, "POST", this.state.post).then(post => {
            if(post.student){
                post.student = post.student.id;
            }
            this.setState({post: post});
            this.props.history.push('/app/comments');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    create = () => {
        this.setState({loading: true});
        let {post} = this.state;

        Api.call(`/comments`, "POST", post, false).then(post => {
            if(post.student){
                post.student = post.student.id;
            }
            this.setState({post: post});

            this.props.history.push('/app/comments');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    onChange = (key, value) => {
        let {post} = this.state;
        post[key] = value;
        this.setState({post: post});
    };

    setToValue = (obj, value, path) => {
        path = path.split('.');
        var i;
        for (i = 0; i < path.length - 1; i++)
            obj = obj[path[i]];

        obj[path[i]] = value;
    }

    onFileChange = (key, value) => {
        console.log(key, value);
        let {files} = this.state;
        files[key] = value;
        var reader = new FileReader();

        this.setState({files: files});
        let url = reader.readAsDataURL(value);

        reader.onloadend = function (e) {
            this.setState({
                previewUrl: [reader.result]
            });
        }.bind(this);
    };


    render() {
        let {post} = this.state;
        return (
            <div className="dashboard">

                <Heading name="Edit"/>

                <div style={{marginTop: '70px'}}>
                    <Row>
                        <Col md={6}>
                            <Form autoComplete={'off'} onSubmit={this.submit}>


                                <Form.Group controlId={'student'}>
                                    <Form.Label>Student</Form.Label>
                                    <Form.Control as={'select'} value={post.student}
                                                  onChange={event => this.onChange('student', event.target.value)}>
                                        <option value={''}></option>
                                        {this.state.users.map(category => {
                                            return <option value={category.id}>{`${category.firstName} ${category.lastName}`.trim()}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="week">
                                    <Form.Label>Week</Form.Label>
                                    <Form.Control type="number" value={post.week}
                                                  onChange={event => this.onChange('week', event.target.value)}/>
                                </Form.Group>
                                <Form.Group controlId="comment">
                                    <Form.Label>Comment</Form.Label>
                                    <Form.Control as="textarea" value={post.comment}
                                                  onChange={event => this.onChange('comment', event.target.value)}/>
                                </Form.Group>

                                <SubmitButton label={'Submit'} loading={this.state.loading}/>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}


export default CommentForm
