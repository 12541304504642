import React, {Component} from 'react';
import LinkWithoutImage from "./LinkWithoutImage";
import paginate from "../../helpers/Paginate";
import {Form} from "react-bootstrap";


class Pagination extends Component {
    render() {

        let {perPage, currentPage, total} = this.props;
        if (perPage === 'All') {
            perPage = total;
        }


        let pages = paginate(total, currentPage, perPage);


        console.log(pages);
        return (
            <div className="pagination">
                <ul className="pagination">
                    {currentPage > 1 &&
                    <li className="page-item">
                        <LinkWithoutImage onPaginate={this.props.onPaginate} page={currentPage - 1} buttonText="&laquo;"
                                          className="page-link"/>
                    </li>
                    }

                    {pages.pages.map(page => {
                        return <li className={currentPage === page ? "page-item active" : "page-item"} key={page}>
                            <LinkWithoutImage onPaginate={this.props.onPaginate} page={page} buttonText={page}
                                              className="page-link"/>
                        </li>
                    })}

                    {currentPage < pages.endPage &&
                    <li className="page-item">
                        <LinkWithoutImage onPaginate={this.props.onPaginate} page={currentPage + 1} buttonText="&raquo;"
                                          className="page-link"/>
                    </li>
                    }

                    <li>
                        <Form autoComplete={'off'} onSubmit={this.submit}>
                            <Form.Group controlId="title">
                                <Form.Control as={'select'} value={this.props.perPage}
                                              onChange={event => this.props.onChangePerPage(event.target.value)}>
                                    {[10,20,50,100,200,500,1000,2000,5000,10000].map(category => {
                                        return <option value={category}>{category}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </li>
                    <li>
                        <LinkWithoutImage onPaginate={this.props.onExport} page={currentPage - 1} buttonText="Export"
                                          className="page-link"/>
                    </li>
                </ul>
            </div>
        );
    }
}

export default Pagination;
