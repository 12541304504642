import Api from "./Api";

export default class DataStore {

    static _data = {};

    static initialize = async () => {


        try {
            const value = await localStorage.getItem('DataStore');
            if (value !== null) {
                // We have data!!
                DataStore._data = JSON.parse(value);
            }
        } catch (error) {
            console.log("Error while initializing Datastore",error);
        }
    };
    static clear =()=>{
        DataStore._data = {};
        DataStore._save();
    } ;


    static _set = (key, value) => {
        console.log("Saving ",key,"value is ",value);
        DataStore._data[key] = value;
        setTimeout(async () => {
            DataStore._save();
        }, 0);
    };

    static _save = async () => {
        try {
            await localStorage.setItem('DataStore', JSON.stringify(DataStore._data));
        } catch (error) {
            // Error saving data
        }
    };

    static setUser(user) {
        DataStore._set('user',user);
    }
    static setToken(token) {
        DataStore._set("token",token);
    }

    static getUser(){
        return DataStore._data["user"];
    }
    static getToken(){
        console.log("Datastore is ",DataStore._data);
        return DataStore._data["token"];
    }
}
