import React, {Component} from 'react';
import {Link} from "react-router-dom";


class LinkWithoutImage extends Component {
    
    render() {
        return (
            <Link href="#" onClick={() => {
                this.props.onPaginate(this.props.page)
            }} className={this.props.className}>
                {this.props.buttonText}
            </Link>
        );
    }
}

export default LinkWithoutImage;
