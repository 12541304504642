import React from 'react'
import {Form, Button, Row, Col} from "react-bootstrap";
import Api from "../../../helpers/Api";
import SubmitButton from "../components/SubmitButton";
import Heading from "../../../Components/Heading/Heading";

class PostForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            post: {},
            files: []
        }
    }

    componentDidMount() {
        let {match: {params}} = this.props;
        if (params.id) {
            this.loadData(params.id);
        }
    }

    loadData = (id) => {
        if (id === 'new') {
            return;
        }
        Api.call(`/plans/${id}`).then(post => {
            this.setState({post});
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        });
    };

    submit = (e) => {
        e.preventDefault();
        if (this.state.post.id) {
            this.update();
        } else {
            this.create();
        }
    };

    update = () => {
        this.setState({loading: true});
        Api.call(`/plans/${this.state.post.id}`, "POST", this.state.post).then(post => {
            this.setState({post: post});
            this.props.history.push('/app/plans');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    create = () => {
        this.setState({loading: true});
        let {post, files} = this.state;
        let file = files.thumbnail;


        let formData = new FormData();
        formData.append("data", JSON.stringify(post));
        console.log(JSON.stringify(post));
        console.log(JSON.stringify(post));
        if (file) {
            formData.append("files[thumbnail]", file);
        }
        console.log(formData);
        Api.call(`/plans`, "POST", post, false).then(post => {
            this.setState({post: post});

            this.props.history.push('/app/plans');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    onChange = (key, value) => {
        console.log(key, value);
        let {post} = this.state;
        post[key] = value;
        this.setState({post: post});
    };

    onFileChange = (key, value) => {
        console.log(key, value);
        let {files} = this.state;
        files[key] = value;
        var reader = new FileReader();

        this.setState({files: files});
        let url = reader.readAsDataURL(value);

        reader.onloadend = function (e) {
            this.setState({
                previewUrl: [reader.result]
            });
        }.bind(this);
    };


    render() {
        let {post} = this.state;
        return (
            <div className="dashboard">

                <Heading name="Edit"/>

                <div style={{marginTop: '70px'}}>
                    <Row>
                        <Col md={6}>
                            <Form autoComplete={'off'} onSubmit={this.submit}>
                                <Form.Group controlId="price">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control type="number" value={post.price}
                                                  onChange={event => this.onChange('price', event.target.value)}
                                                  placeholder=""/>
                                </Form.Group>
                                <Form.Group controlId="shortDescription">
                                    <Form.Label>Short Description</Form.Label>
                                    <Form.Control as="textarea" value={post.shortDescription}
                                                  onChange={event => this.onChange('shortDescription', event.target.value)}
                                                  rows="2"/>
                                </Form.Group>
                                <Form.Group controlId="description1">
                                    <Form.Label>Description Step 1</Form.Label>
                                    <Form.Control as="textarea" value={post.descriptionStep1}
                                                  onChange={event => this.onChange('descriptionStep1', event.target.value)}
                                                  rows="2"/>
                                </Form.Group>
                                <Form.Group controlId="description2">
                                    <Form.Label>Description Step 2</Form.Label>
                                    <Form.Control as="textarea" value={post.descriptionStep2}
                                                  onChange={event => this.onChange('descriptionStep2', event.target.value)}
                                                  rows="2"/>
                                </Form.Group>
                                <Form.Group controlId="enabled">
                                    <Form.Check type="checkbox" checked={post.enabled}
                                                onChange={event => this.onChange('enabled', event.target.checked)}
                                                label="Enabled"/>
                                </Form.Group>

                                <SubmitButton label={'Submit'} loading={this.state.loading}/>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default PostForm
