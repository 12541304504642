import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import Api from "../../../../helpers/Api";

const FileUpload = ({data, label, onChange}) => {

    const onFileChange = (key, value) => {
        if (!value) {
            onChange('');
        }

        let reader = new FileReader();
        reader.readAsDataURL(value);

        reader.onloadend = () => {
            const image = reader.result;
            Api.call(`/programs/files/upload`, "POST", JSON.stringify({image}), true).then(response => {
                onChange(response.url)
            })
                .catch(console.log)
                .finally(() => {});
        }
    };

    return <Form.Group controlId="enabled">
        <Form.Group controlId={`file-${label}`}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                type='file'
                onChange={event => onFileChange('image', event.target.files[0])}
                placeholder=""/>
        </Form.Group>
        {data &&
        <>
            <img src={Api.getImageUrl(data, false)} width={200} alt='Image'/><br/>
        </>
        }
    </Form.Group>;

}

export default FileUpload;
