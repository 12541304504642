import React from 'react'
import {Col, Form, Row} from "react-bootstrap";
import Api from "../../../helpers/Api";
import SubmitButton from "../components/SubmitButton";
import Heading from "../../../Components/Heading/Heading";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import HTMLEditor from "../../../Components/HTMLEditor";
import FileUpload from "../Programs/FileUpload/FileUpload";

class PostForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            post: {content: EditorState.createEmpty(), excerpt:''},
            files: [],
            users:[],
        }
    }

    componentDidMount() {
        let {match: {params}} = this.props;
        if (params.id) {
            this.loadData(params.id);
        }
        Api.call(`/users?_sort=firstName:ASC`).then(posts => {
            this.setState({users: posts})
        }).catch(console.log).finally(_ => {

        });
    }

    loadData = (id) => {
        if (id === 'new') {
            return;
        }
        Api.call(`/posts/${id}`).then(post => {
            const contentBlock = htmlToDraft(post.content);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                post.content = EditorState.createWithContent(contentState);
            }
            if(!post.excerpt){
                post.excerpt = '';
            }
            if(post.author){
                post.author = post.author.id;
            }
            this.setState({post});
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        });
    };

    submit = (e) => {
        e.preventDefault();
        if (this.state.post.id) {
            this.update();
        } else {
            this.create();
        }
    };

    update = () => {
        this.setState({loading: true});
        Api.call(`/posts/${this.state.post.id}`, "POST", {...this.state.post, content: draftToHtml(convertToRaw(this.state.post.content.getCurrentContent()))}).then(post => {
            //this.setState({post: post});
            this.props.history.push('/app/posts');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    create = () => {
        this.setState({loading: true});
        let {post, files} = this.state;
        let file = files.thumbnail;


        let formData = new FormData();
        formData.append("data", JSON.stringify(post));
        console.log(JSON.stringify(post));
        console.log(JSON.stringify(post));
        if (file) {
            formData.append("files[thumbnail]", file);
        }
        console.log(formData);

        Api.call(`/posts`, "POST", {...post, content: draftToHtml(convertToRaw(this.state.post.content.getCurrentContent()))}, false).then(post => {
            //this.setState({post: post});

            this.props.history.push('/app/posts');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    onChange = (key, value) => {
        console.log(key, value);
        let {post} = this.state;
        post[key] = value;
        this.setState({post: post});
    };

    onFileChange = (key, value) => {
        console.log(key, value);
        let {files} = this.state;
        files[key] = value;
        var reader = new FileReader();

        this.setState({files: files});
        let url = reader.readAsDataURL(value);

        reader.onloadend = function (e) {
            this.setState({
                previewUrl: [reader.result]
            });
        }.bind(this);
    };


    render() {
        let {post} = this.state;
        return (
            <div className="dashboard">

                <Heading name="Edit"/>

                <div style={{marginTop: '70px'}}>
                    <Row>
                        <Col md={6}>
                            <Form autoComplete={'off'} onSubmit={this.submit}>
                                <Form.Group controlId={'author'}>
                                    <Form.Label>Author</Form.Label>
                                    <Form.Control as={'select'} value={post.author}
                                                  onChange={event => this.onChange('author', event.target.value)}>
                                        <option value={''}></option>
                                        {this.state.users.map(category => {
                                            return <option value={category.id}>{`${category.firstName} ${category.lastName}`.trim()}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="key">
                                    <Form.Label>Url</Form.Label>
                                    <Form.Control value={post.key}
                                                  onChange={event => this.onChange('key', event.target.value)}
                                                  placeholder=""/>
                                </Form.Group>
                                <Form.Group controlId="date">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control type={'date'} value={post.date}
                                                  onChange={event => this.onChange('date', event.target.value)}
                                                  placeholder=""/>
                                </Form.Group>
                                <Form.Group controlId="duration">
                                    <Form.Label>Duration (mins)</Form.Label>
                                    <Form.Control value={post.duration}
                                                  onChange={event => this.onChange('duration', event.target.value)}
                                                  placeholder=""/>
                                </Form.Group>
                                <Form.Group controlId="shortDescription">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control value={post.title}
                                                  onChange={event => this.onChange('title', event.target.value)}
                                    />
                                </Form.Group>

                                <FileUpload label="Featured Image" data={post.image} onChange={v => this.onChange('image', v)}/>
                                <Form.Group controlId="excerpt">
                                    <Form.Label>Excerpt</Form.Label>
                                    <Form.Control as="textarea" value={post.excerpt}
                                                  onChange={event => this.onChange('excerpt', event.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="description1">
                                    <Form.Label>Content</Form.Label>
                                    <Editor
                                        editorState={this.state.post.content}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={s=>this.setState({post: {...post, content: s}})}
                                    />
                                </Form.Group>

                                <Form.Group controlId="enabled">
                                    <Form.Check type="checkbox" checked={post.enabled}
                                                onChange={event => this.onChange('enabled', event.target.checked)}
                                                label="Enabled"/>
                                </Form.Group>

                                <Form.Group controlId="featured">
                                    <Form.Check type="checkbox" checked={post.featured}
                                                onChange={event => this.onChange('featured', event.target.checked)}
                                                label="Featured"/>
                                </Form.Group>

                                <Form.Group controlId="views">
                                    <Form.Label>Views</Form.Label>
                                    <Form.Control type={'number'} value={post.views}
                                                  onChange={event => this.onChange('views', event.target.value)}
                                                  placeholder=""/>
                                </Form.Group>

                                <SubmitButton label={'Submit'} loading={this.state.loading}/>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default PostForm
